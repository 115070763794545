import * as React from 'react'

import { Container, Typography } from '@mui/material'

import { SeoHeaders } from 'shared/components'

import NavBar from '../components/nav_bar'

const browser = (typeof window !== 'undefined') && window

const NotFoundPage = () => (browser && (
  <React.Fragment>
    <SeoHeaders title='No encontrado' />
    <NavBar />
    <Container
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
        gap: 2,
        px: 2,
        pt: 2,
        pb: 4,
      })}
    >
      <Typography
        variant='h1'
      >
        404
      </Typography>
      <Typography
        variant='h4'
        component='h2'
        textAlign='center'
      >
        ¡Aquí no hay nadie excepto nosotros, las complicadas máquinas pensantes! 🤖
      </Typography>
    </Container>
  </React.Fragment>
))

export default NotFoundPage
